<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Event : Groups
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'event.groups.create' }" tag="li">
              <a>
                <i class="material-icons">add</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="event-groups-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Slug</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="group in groups" :key="group.id">
                <td>{{ group.id }}</td>
                <td>{{ group.title }}</td>
                <td>{{ group.slug }}</td>
                <td class="text-center">
                  <router-link :to="{ name: 'event.groups.edit', params: { id: group.id }}">
                    <a>
                      <i class="material-icons">mode_edit</i>
                    </a>
                  </router-link>
                  <a href="#" @click="deleteGroup(group.id)">
                    <i class="material-icons">delete</i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        groups: 'events/groups',
      }),
    },
    methods: {
      ...mapActions({
        fetchGroups: 'events/fetchGroups',
        deleteGroup: 'events/deleteGroup',
      }),
    },
    mounted() {
      $('#event-groups-table').DataTable({
        responsive: true,
      });
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchGroups()
      })
    }
  }
</script>