import axios from 'axios';

const auth = {
  grant_type: 'client_credentials',
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  scope: '*',
};

const state = {
  token: '',
}

const getters = {
  token: state => state.token,
}

const mutations = {
  token: (state, payload) => state.token = payload,
}

const actions = {
  fetchToken({ commit }) {
    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/oauth/token', 
        data: auth
      }).then(response => {
        commit('token', response.data.access_token)

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
        
        localStorage.setItem('token', response.data.access_token)
        
        resolve()
      }).catch(() => {
        localStorage.removeItem('token')
        
        reject()
      })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}