<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Items
          </h2>
        </div>
        <div class="body">
          <table id="items-table" class="table table-striped table-editer">
            <thead>
              <tr>
                <th>ID</th>
                <th>Group</th>
                <th>Code</th>
                <th>Status</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.ticket_group_id }}</td>
                <td>{{ item.code }}</td>
                <td><span class="label" v-bind:class="itemStatus(item)">{{ item.status }}</span></td>
                <td class="text-center">
                  <span v-if="item.status != 'USED' && item.status != 'AVAILABLE'">
                    <a href="#" @click="updateItem({ id: item.id, data: { status: 'AVAILABLE' } })"><i class="material-icons">cancel</i></a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        items: 'items/items',
      }),
    },
    methods: {
      ...mapActions({
        fetchItems: 'items/fetchItems',
        updateItem: 'items/updateItem',
      }),
      itemStatus: (item) => {
        return {
          'label-success': (item.status == 'AVAILABLE'), 
          'label-primary': (item.status == 'USED'),
          'label-danger': (item.status == 'DISABLED'),
          'label-warning': (item.status == 'RESERVED'),
        }
      },
    },
    mounted() {
      $('#items-table').DataTable({
        responsive: true,
      });
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchItems()
      })
    }
  }
</script>