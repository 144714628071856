import axios from 'axios'
import router from '@/router/router'

const state = {
  group: [],
  groups: [],
  item: [],
  items: [],
  image: [],
  images: [],
  ticketGroup: [],
}

const getters = {
  group: state => state.group,
  groups: state => state.groups,
  event: state => state.item,
  events: state => state.items,
  image: state => state.image,
  images: state => state.images,
  ticketGroup: state => state.ticketGroup,
}

const mutations = {
  group: (state, payload) => state.group = payload,
  groups: (state, payload) => state.groups = payload,
  event: (state, payload) => state.item = payload,
  events: (state, payload) => state.items = payload,
  image: (state, payload) => state.image = payload,
  images: (state, payload) => state.images = payload,
  ticketGroup: (state, payload) => state.ticketGroup = payload,
}

const actions = {
  async fetchEvents({ commit }) {
    $('.page-loader-wrapper').fadeIn(100)

    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: '/v1/events', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        params: {
          'order_by': 'id,asc',
        },
      })
      .then(response => {
        commit('events', response.data.data)

        $('.page-loader-wrapper').fadeOut()

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async fetchEvent({ commit }, id) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: '/v1/events/' + id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(response => {
        //  mapping data
        let location = response.data.data.location

        try {
          response.data.data.location = location.title
          response.data.data.lat = location.lat
          response.data.data.lng = location.lng
        } catch (error) {
          response.data.data.location = ''
          response.data.data.lat = ''
          response.data.data.lng = ''
        }

        commit('event', response.data.data)

        $('.page-loader-wrapper').fadeOut();

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async createEvent({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/v1/events', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload,
      })
      .then(() => {
        showMessage('Event created!', 'Your event created!', 'success')

        router.push('/events')

        dispatch('fetchEvents')

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async updateEvent({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: '/v1/events/' + payload.id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })
      .then(() => {
        showMessage('Event updated!', 'Your event updated!', 'success')

        dispatch('fetchEvent', payload.id)

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async deleteEvent({ dispatch }, id) {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete it!'
    }, function (isConfirm) {
      if (isConfirm) {
        return new Promise(function (resolve, reject) {
          axios({
            method: 'delete',
            url: '/v1/events/' + id, 
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
            },
          })
          .then(() => {
            dispatch('fetchEvents')

            router.push('/events')

            showMessage('Event deleted!', 'Your event deleted!', 'success')

            resolve()
          })
          .catch((error) => {
            $('.page-loader-wrapper').fadeOut()

            if (typeof error.response.data != 'undefined' && error.response.data) {
              showMessage(error.response.data.status, error.response.data.message, 'error')
            } else {
              // 
            }

            reject(error)
          })
        })
      }
    })
  },
  async fetchGroups({ commit }) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/event-groups', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        params: {
          'order_by': 'id,asc',
          'limit': 10000,
        },
      })

      commit('groups', response.data.data)
    } catch (error) {
      // 
    }
  },
  async fetchGroup({ commit }, id) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/event-groups/' + id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('group', response.data.data)
    } catch (error) {
      // 
    }
  },
  async createGroup({ dispatch }, payload) {
    try {
      await axios({
        method: 'post',
        url: '/v1/event-groups',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Group created!', 'Your event group created!', 'success')

      router.push('/event-groups')
    } catch (error) {
      // 
    }
  },
  async updateGroup({ dispatch }, payload) {
    try {
      await axios({
        method: 'put',
        url: '/v1/event-groups/' + payload.id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Group updated!', 'Your event group updated!', 'success')

      router.push('/event-groups')
    } catch (error) {
      // 
    }
  },
  async deleteGroup({ dispatch }, id) {
    try {
      await axios({
        method: 'delete',
        url: '/v1/event-groups/' + id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      showMessage('Group deleted!', 'Your event group deleted!', 'success')

      dispatch('fetchGroups')
    } catch (error) {
      // 
    }
  },
  async fetchTicketGroup({ commit }, id) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: '/v1/ticket-groups/' + id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })
      .then(response => {
        commit('ticketGroup', response.data.data)

        $('.page-loader-wrapper').fadeOut();

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async createTicketGroup({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/v1/ticket-groups', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload,
      })
      .then(() => {
        showMessage('Ticket created!', 'Your ticket created!', 'success')

        router.push('/events/' + payload.event_id + '/tickets')

        dispatch('fetchEvent', payload.event_id)

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async updateTicketGroup({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: '/v1/ticket-groups/' + payload.ticket_groups_id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })
      .then(() => {
        showMessage('Ticket updated!', 'Your ticket updated!', 'success')

        dispatch('fetchTicketGroup', payload.ticket_groups_id)

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async deleteTicketGroup({ dispatch }, payload) {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete it!'
    }, function (isConfirm) {
      if (isConfirm) {
        return new Promise(function (resolve, reject) {
          axios({
            method: 'delete',
            url: '/v1/ticket-groups/' + payload.ticket_groups_id, 
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
            },
          })
          .then(() => {
            dispatch('fetchEvent', payload.event_id)

            router.push('/events/' + payload.event_id + '/tickets')

            showMessage('Event deleted!', 'Your event deleted!', 'success')

            resolve()
          })
          .catch((error) => {
            $('.page-loader-wrapper').fadeOut()

            if (typeof error.response.data != 'undefined' && error.response.data) {
              showMessage(error.response.data.status, error.response.data.message, 'error')
            } else {
              // 
            }

            reject(error)
          })
        })
      }
    })
  },
  async fetchImage({ commit }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'get',
        url: '/v1/events/' + payload.event_id + '/images/' + payload.image_id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })
      .then(response => {
        // console.log(response.data.data)

        commit('image', response.data.data)

        $('.page-loader-wrapper').fadeOut();

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async createImage({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: '/v1/events/' + payload.event_id + '/images', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })
      .then(() => {
        showMessage('Image created!', 'Your image created!', 'success')

        router.push('/events/' + payload.event_id + '/images')

        dispatch('fetchEvent', payload.event_id)

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async updateImage({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100);

    return new Promise(function (resolve, reject) {
      axios({
        method: 'put',
        url: '/v1/events/' + payload.event_id + '/images/' + payload.image_id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })
      .then(() => {
        showMessage('Image updated!', 'Your image updated!', 'success')

        dispatch('fetchImage', {
          event_id: payload.event_id, 
          image_id: payload.image_id
        })

        resolve()
      })
      .catch((error) => {
        $('.page-loader-wrapper').fadeOut()

        if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
          showMessage(error.response.data.status, error.response.data.message, 'error')
        } else if (error.response.data.data) {
          let html = '';

          $.each(error.response.data.data, function(key, val) {
            html += (key + ': ' + val + '</br>');
          })

          showMessage(error.response.data.status, html, 'error')
        }

        reject(error)
      })
    })
  },
  async deleteImage({ dispatch }, payload) {
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete it!'
    }, function (isConfirm) {
      if (isConfirm) {
        return new Promise(function (resolve, reject) {
          axios({
            method: 'delete',
            url: '/v1/events/' + payload.event_id + '/images/' + payload.image_id,
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
            },
          })
          .then(() => {
            dispatch('fetchEvent', payload.event_id)

            router.push('/events/' + payload.event_id + '/images')

            showMessage('Image deleted!', 'Your image deleted!', 'success')

            resolve()
          })
          .catch((error) => {
            $('.page-loader-wrapper').fadeOut()

            if (typeof error.response.data != 'undefined' && error.response.data) {
              showMessage(error.response.data.status, error.response.data.message, 'error')
            } else {
              // 
            }

            reject(error)
          })
        })
      }
    })
  },
  async exportReport({ commit }, payload = {}) {
    axios({
      method: 'get',
      url: '/v1/orders/export?event_id=' + payload.id, 
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'event_' + payload.id + '_order_report.xlsx');

      document.body.appendChild(link);

      link.click();
    })
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}