<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Events
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'events.create' }" tag="li">
              <a>
                <i class="material-icons">add</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="events-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Slug</th>
                <th class="text-center">State</th>
                <th class="text-center">Start</th>
                <th class="text-center">End</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in events" :key="event.id">
                <td>{{ event.id }}</td>
                <td>{{ event.title }}</td>
                <td>{{ event.slug }}</td>
                <td class="text-center"><span class="label" v-bind:class="eventStatus(event)">{{ event.state }}</span></td>
                <td class="text-center">{{ event.start_date }}</td>
                <td class="text-center">{{ event.end_date }}</td>
                <td class="text-center">
                   <router-link :to="{ name: 'event.orders', params: { id: event.id }}">
                    <a>
                      <i class="material-icons">reorder</i>
                    </a>
                  </router-link>
                  <router-link :to="{ name: 'event.images', params: { id: event.id }}">
                    <a>
                      <i class="material-icons">panorama</i>
                    </a>
                  </router-link>
                  <router-link :to="{ name: 'events.edit', params: { id: event.id }}">
                    <a>
                      <i class="material-icons">mode_edit</i>
                    </a>
                  </router-link>
                  <router-link :to="{ name: 'event.tickets', params: { id: event.id }}">
                    <a>
                      <i class="material-icons">stay_current_portrait</i>
                    </a>
                  </router-link>
                  <a href="#!" @click="exportReport({ id: event.id })"><i class="material-icons">assessment</i></a>
                  <!-- <a href="#" @click="deleteEvent(event.id)"><i class="material-icons">delete</i></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        events: 'events/events',
      }),
    },
    methods: {
      ...mapActions({
        fetchEvents: 'events/fetchEvents',
        deleteEvent: 'events/deleteEvent',
        exportReport: 'events/exportReport',
      }),
      eventStatus: (event) => {
        return {
          'label-success': (event.state == 'NORMAL'), 
          'label-primary': (event.state == 'OPEN'),
          'label-danger': (event.state == 'CLOSE'),
        }
      },
    },
    mounted() {
      $('#events-table').DataTable({
        responsive: true,
      });
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchEvents()
      })
    }
  }
</script>