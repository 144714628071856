import axios from 'axios'

const state = {
  items: [],
}

const getters = {
  items: state => state.items,
}

const mutations = {
  items: (state, payload) => state.items = payload,
}

const actions = {
  async fetchItems({ commit }) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      let response = await axios({
        method: 'get',
        url: '/v1/items', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        params: {
          'order_by': 'id,asc',
          'limit': 10000,
        },
      })

      commit('items', response.data.data)
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
  async updateItem({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      await axios({
        method: 'put',
        url: '/v1/items/' + payload.id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        data: payload.data,
      })

      dispatch('fetchItems')
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}