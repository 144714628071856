<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            User
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'users' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="user-table" class="table table-striped">
            <tbody>
              <tr>
                <th width="10%">Name</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><input type="text" v-model="user.profile.name" class="form-control"></td>
              </tr>
              <tr>
                <th width="10%">Surname</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><input type="text" v-model="user.profile.surname" class="form-control"></td>
              </tr>
              <tr>
                <th width="10%">Email</th>
                <td><input type="text" v-model="user.email" class="form-control"></td>
              </tr>
              <tr>
                <th width="10%">Phone</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><input type="text" v-model="user.profile.phone" class="form-control"></td>
              </tr>
              <!-- <tr>
                <th width="10%">Citizen ID</th>
                <td>{{ user.profile.id_card }}</td>
              </tr> -->
              <tr>
                <th width="10%"></th>
                <td>
                  <a href="#" @click="updateUser({ id: user.id, data: { 
                    name: user.profile.name,
                    surname: user.profile.surname,
                    email: user.email,
                    phone: user.profile.phone
                  }})">
                    <button type="button" class="btn btn-primary m-t-15 waves-effect">Confirm</button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        user: 'users/user',
      }),
    },
    methods: {
      ...mapActions({
        fetchUser: 'users/fetchUser',
        updateUser: 'users/updateUser',
      }),
    },
    mounted() {

    },
    created() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchUser(vm.$route.params.id)
      })
    }
  }
</script>