import axios from 'axios'

const state = {
  messages: [],
}

const getters = {
  messages: state => state.messages,
}

const mutations = {
  messages: (state, payload) => state.messages = payload,
}

const actions = {
  async fetchMessages({ commit }, email) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      let response = await axios({
        method: 'get',
        url: '/v1/mails/events/messages?recipients=' + email + '&from=2018-01-01T00:00&events=delivery', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('messages', response.data.data)
    } catch (err) {
      // console.log(err) 
    }

    $('.page-loader-wrapper').fadeOut(100)
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}