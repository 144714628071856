<template>
  <div id="create-event-page" class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>Create Event</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'events' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <div id="wizard_vertical">
            <!-- <h2>Create Event</h2> -->
            <section>
              <form @submit.prevent="eventSubmit()">
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Group</h2>
                    <select class="form-control show-tick" data-live-search="true" required name="event.event_group_id" v-model="event.event_group_id">
                      <option value="">-- Please select --</option>
                      <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.title }}</option>
                    </select>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Title</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="text" class="form-control" placeholder="Title" required v-model="event.title">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Description</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <textarea rows="3" class="form-control no-resize" placeholder="Description" required v-model="event.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">State</h2>
                    <select class="form-control show-tick" required v-model="event.state">
                      <option value="">-- Please select --</option>
                      <option value="NORMAL">NORMAL</option>
                      <option value="OPEN">OPEN</option>
                      <option value="CLOSE">CLOSE</option>
                    </select>
                  </div>
                  <div class="col-sm-6">
                    <h2 class="card-inside-title">Start Date</h2>
                    <div class="form-group">
                      <div class="form-line">
                        <input type="date" class="form-control datepicker" placeholder="Start Date" required autocomplete="off" name="event.start_date" v-model="event.start_date">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <h2 class="card-inside-title">End Date</h2>
                    <div class="form-group">
                      <div class="form-line">
                        <input type="date" class="form-control datepicker" placeholder="End Date" required autocomplete="off" name="event.end_date" v-model="event.end_date">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Location</h2>
                    <div class="form-group">
                      <div class="form-line">
                        <input id="pac-input" class="form-control controls" type="text" placeholder="Search Box" required v-model="event.location">
                      </div>
                    </div>
                    <div id="map"></div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-lg btn-primary waves-effect">SAVE</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #map {
    height: 500px;
    width: 100%;
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data() {
      return {
        event: {
          event_group_id: '',
          title: '',
          description: '',
          start_date: '',
          end_date: '',
          state: '',
          lat: '',
          lng: '',
          location: '',
        }
      }
    },
    computed: {
      ...mapGetters({
        groups: 'events/groups',
      }),
    },
    methods: {
      ...mapActions({
        fetchGroups: 'events/fetchGroups',
        createEvent: 'events/createEvent',
      }),
      eventSubmit: function() {
        this.event.location = $('#pac-input').val()
        this.event.lat = map.center.lat()
        this.event.lng = map.center.lng()
        this.createEvent(this.event).then(() => {
          Object.assign(this.$data, this.$options.data.call(this))
        })
      },
      setDatePicker: function() {
        this.event.start_date = $('input[name="event.start_date"]').val()
        this.event.end_date = $('input[name="event.end_date"]').val()
      }
    },
    mounted: function () {
      // disabled enter key
      $(document).ready(function() {
        $(window).keydown(function(event) {
          if(event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });
      });
      
      $.AdminBSB.input.activate();
      $.AdminBSB.select.activate();
    },
    updated: function() {

    },
    created: function() {
      // load map
      this.$loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCaAkUdtDwS-5Jp7D37XuRdYT1Vlk2udr4&libraries=places&callback=initAutocomplete")
      .then(() => {})
      .catch(() => {})
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchGroups()

        if (typeof google === 'object' && typeof google.maps === 'object') {
          initAutocomplete()
        }
      })
    }
  }
</script>