import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/router'
import store from '@/store/store'
import axios from 'axios';
import LoadScript from 'vue-plugin-load-script';
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production';

Vue.use(LoadScript);

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')