var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v("Create Event Group")]),_c('ul',{staticClass:"header-dropdown m-r--5"},[_c('router-link',{attrs:{"to":{ name: 'event.groups' },"tag":"li","exact":""}},[_c('a',[_c('i',{staticClass:"material-icons"},[_vm._v("keyboard_backspace")])])])],1)]),_c('div',{staticClass:"body"},[_c('div',[_c('section',[_c('form',{attrs:{"id":"ticket-group-form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-sm-12"},[_c('h2',{staticClass:"card-inside-title"},[_vm._v("Title")]),_c('div',{staticClass:"form-group form-float"},[_c('div',{staticClass:"form-line"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group.title),expression:"group.title"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Title","required":""},domProps:{"value":(_vm.group.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.group, "title", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('h2',{staticClass:"card-inside-title"},[_vm._v("Description")]),_c('div',{staticClass:"form-group form-float"},[_c('div',{staticClass:"form-line"},[_c('editor',{attrs:{"api-key":"8wibi90v3ksearc8tgn8v5pciz77thdrbnzjd7njkfldw8g6","init":{
                        theme: 'modern',
                        height: 300,
                        plugins: [
                          'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                          'searchreplace wordcount visualblocks visualchars code fullscreen',
                          'insertdatetime media nonbreaking save table contextmenu directionality',
                          'emoticons template paste textcolor colorpicker textpattern imagetools'
                        ],
                        toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                        toolbar2: 'print preview media | forecolor backcolor emoticons',
                        image_advtab: true
                      }},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}})],1)])])]),_c('button',{staticClass:"btn btn-block btn-lg btn-primary waves-effect",attrs:{"type":"submit"}},[_vm._v("SAVE")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }