<template>
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>Create Event Group</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'event.groups' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <div>
            <section>
              <form id="ticket-group-form" @submit.prevent="formSubmit()" novalidate>
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Title</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="text" class="form-control" placeholder="Title" required v-model="group.title">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Description</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <editor api-key="8wibi90v3ksearc8tgn8v5pciz77thdrbnzjd7njkfldw8g6" v-model="group.description" :init="{
                          theme: 'modern',
                          height: 300,
                          plugins: [
                            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                            'searchreplace wordcount visualblocks visualchars code fullscreen',
                            'insertdatetime media nonbreaking save table contextmenu directionality',
                            'emoticons template paste textcolor colorpicker textpattern imagetools'
                          ],
                          toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                          toolbar2: 'print preview media | forecolor backcolor emoticons',
                          image_advtab: true
                        }"></editor>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-lg btn-primary waves-effect">SAVE</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Editor from '@tinymce/tinymce-vue';

  export default {
    data() {
      return {
        group: {
          title: '',
          description: ''
        }
      }
    },
    computed: {
      ...mapGetters({

      }),
    },
    methods: {
      ...mapActions({
        createGroup: 'events/createGroup',
      }),
      formSubmit: function() {
        this.createGroup({ data: this.group }).then(() => {
          Object.assign(this.$data, this.$options.data.call(this))
        })
      },
    },
    components: {
      'editor': Editor
    },
    mounted: function () {
      $.AdminBSB.input.activate();
      $.AdminBSB.select.activate();
    },
    updated: function() {

    },
    created: function() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        
      })
    }
  }
</script>