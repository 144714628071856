<template>
  <div class="">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="header">
            <h2>
              QR - Success
            </h2>
            <ul class="header-dropdown m-r--5">
              <!-- <router-link :to="{ name: 'qr' }" tag="li" exact> -->
                <a href="/qr">
                  <i class="material-icons">keyboard_backspace</i>
                </a>
              <!-- </router-link> -->
            </ul>
          </div>
          <div class="body">
            <div class="text-center m-t-5 m-b-5">
              <p class="" style="font-size: 16px; color: green;">Verify succeed!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  const axios = require('axios').default;

  export default {
    computed: {},
    methods: {},
    mounted() {

    },
    created() {

    }
  }
</script>