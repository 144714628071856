<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Users
          </h2>
        </div>
        <div class="body">
          <table id="users-table" class="table table-striped table-editer">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Name</th>
                <th>Phone</th>
                <th class="text-center">Activate</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.id }}</td>
                <td>{{ user.email }}</td>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile">{{ user.profile.name }} {{ user.profile.surname }}</td>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile">{{ user.profile.phone }}</td>
                <td class="text-center" v-if="user.is_activate == 1"><span class="label label-success">Yes</span></td>
                <td class="text-center" v-if="user.is_activate == 0"><span class="label label-danger">No</span></td>
                <td class="text-center">
                  <router-link :to="{ name: 'users.edit', params: { id: user.id }}">
                    <a>
                      <i class="material-icons">mode_edit</i>
                    </a>
                  </router-link>
                   <router-link :to="{ name: 'users.orders', params: { id: user.id }}">
                    <a>
                      <i class="material-icons">reorder</i>
                    </a>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  var table;

  export default {
    computed: {
      ...mapGetters({
        users: 'users/users',
      }),
    },
    methods: {
      ...mapActions({
        fetchUsers: 'users/fetchUsers',
      }),
    },
    mounted() {
      setTimeout(function() {
        $('.page-loader-wrapper').fadeIn(100)
      }, 200)
    },
    watch: {
      users: function() {
        setTimeout(function() {
          $('.page-loader-wrapper').fadeOut(100)
          
          table = $('#users-table').DataTable({
            responsive: true,
          });
        }, 500)
      }
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchUsers()
      })
    }
  }
</script>