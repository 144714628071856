import axios from 'axios'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
}

const mutations = {
  authLoadingState: (state) => {
    state.status = 'loading'
  },
  authSuccessState: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  authErrorState: (state) => {
    state.status = 'error'
  },
  authLogoutState: (state) => {
    state.status = ''
    state.token = ''
  },
}

const actions = {
  async authLogin({ commit }, user) {
    return new Promise(function (resolve, reject) {
      commit('authLoadingState')
      axios({
        url: '/v1/login', 
        data: user, 
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      }).then(response => {
        localStorage.setItem('user-token', response.data.data.access_token)
        
        commit('authSuccessState', response.data.data.access_token)

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.access_token;
        
        resolve(response)
      }).catch(error => {
        commit('authErrorState', error)
        
        localStorage.removeItem('user-token')
        
        reject(error)
      })
    })
  },
  async authLogout({ commit }) {
    commit('authLogoutState')
    commit('user', [])

    localStorage.removeItem('user-token')
    localStorage.removeItem('user')
  },
}

export default {
	state,
	getters,
	mutations,
	actions,
}