import axios from 'axios'

const state = {
  item: localStorage.getItem('user') || [],
}

const getters = {
  user: state => state.item,
}

const mutations = {
  user: (state, payload) => state.item = payload,
}

const actions = {
  async fetchUser({ commit }) {
    axios({
      method: 'get',
      url: '/v1/me', 
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
      }
    })
    .then(response => {
      let data = response.data.data

      data.profile.firstname = response.data.data.profile.name;

      commit('user', data)

      localStorage.setItem('user', response.data.data)
    }).catch(() => {
      commit('user', [])

      localStorage.removeItem('user')
    })
  },
}

export default {
	state,
	getters,
	mutations,
	actions,
}