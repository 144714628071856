<template>
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>Create Ticket</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'tickets', params: { id: group.id }}" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <div>
            <section>
              <form id="ticket-form" @submit.prevent="formSubmit()" novalidate>
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Title</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="text" class="form-control" placeholder="Title" required v-model="ticket.title">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Display</h2>
                    <select class="form-control show-tick" required v-model="ticket.display">
                      <option value="">-- Please select --</option>
                      <option value="SHOW">SHOW</option>
                      <option value="HIDE">HIDE</option>
                    </select>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Description</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <editor api-key="8wibi90v3ksearc8tgn8v5pciz77thdrbnzjd7njkfldw8g6" v-model="ticket.description" :init="{
                          theme: 'modern',
                          height: 300,
                          plugins: [
                              'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                              'searchreplace wordcount visualblocks visualchars code fullscreen',
                              'insertdatetime media nonbreaking save table contextmenu directionality',
                              'emoticons template paste textcolor colorpicker textpattern imagetools'
                          ],
                          toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                          toolbar2: 'print preview media | forecolor backcolor emoticons',
                          image_advtab: true
                        }"></editor>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Price</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="number" class="form-control" placeholder="Price" required v-model="ticket.price">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">จำนวนใบ / ชุด</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="number" class="form-control" placeholder="จำนวนใบ / ชุด" required v-model="ticket.per_ticket">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">จำนวนสูงสุด / คำสั่งซื้อ</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="number" class="form-control" placeholder="จำนวนสูงสุด / คำสั่งซื้อ" required v-model="ticket.max_per_order">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-lg btn-primary waves-effect">SAVE</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Editor from '@tinymce/tinymce-vue';

  export default {
    data() {
      return {
        ticket: {
          title: '',
          display: '',
          description: '',
          price: '',
          per_ticket: '',
          max_per_order: '',
        }
      }
    },
    computed: {
      ...mapGetters({
        group: 'tickets/group',
      }),
    },
    methods: {
      ...mapActions({
        fetchGroup: 'tickets/fetchGroup',
        createTicket: 'tickets/createTicket',
      }),
      formSubmit: function() {
        this.createTicket({ id: this.$route.params.id, data: this.ticket }).then(() => {
          Object.assign(this.$data, this.$options.data.call(this))
        })
      },
    },
    components: {
      'editor': Editor
    },
    mounted: function () {
      $.AdminBSB.input.activate();
      $.AdminBSB.select.activate();
    },
    updated: function() {

    },
    created: function() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchGroup(vm.$route.params.id)
      })
    }
  }
</script>