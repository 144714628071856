import axios from 'axios'
import router from '@/router/router'

const state = {
  group: [],
  groups: [],
  item: [],
  items: [],
}

const getters = {
  group: state => state.group,
  groups: state => state.groups,
  item: state => state.item,
  items: state => state.items,
}

const mutations = {
  group: (state, payload) => state.group = payload,
  groups: (state, payload) => state.groups = payload,
  item: (state, payload) => state.item = payload,
  items: (state, payload) => state.items = payload,
}

const actions = {
  async fetchGroups({ commit }) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/ticket-groups',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        params: {
          'order_by': 'id,asc',
          'limit': 10000,
        },
      })

      commit('groups', response.data.data)
    } catch (error) {
      // 
    }
  },
  async fetchGroup({ commit }, id) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/ticket-groups/' + id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('group', response.data.data)
    } catch (error) {
      // 
    }
  },
  async createGroup({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      await axios({
        method: 'post',
        url: '/v1/ticket-groups',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Group created!', 'Your ticket group created!', 'success')

      router.push('/ticket-groups')

      dispatch('fetchGroups')
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
  async updateGroup({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      await axios({
        method: 'put',
        url: '/v1/ticket-groups/' + payload.id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Group updated!', 'Your ticket group updated!', 'success')

      router.push('/ticket-groups')

      dispatch('fetchGroups')
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
  async deleteGroup({ dispatch }, id) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      await axios({
        method: 'delete',
        url: '/v1/ticket-groups/' + id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      showMessage('Group deleted!', 'Your ticket group deleted!', 'success')

      router.push('/ticket-groups')

      dispatch('fetchGroups')
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
  async fetchTickets({ commit }, id) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/ticket-groups/' + id + '/tickets',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('items', response.data.data)
    } catch (error) {
      // 
    }
  },
  async fetchTicket({ commit }, payload) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/ticket-groups/' + payload.group_id + '/tickets/' + payload.ticket_id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('item', response.data.data)
    } catch (error) {
      // 
    }
  },
  async createTicket({ dispatch }, payload) {
    $('.page-loader-wrapper').fadeIn(100)

    try {
      await axios({
        method: 'post',
        url: '/v1/ticket-groups/' + payload.id + '/tickets',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Ticket created!', 'Your ticket created!', 'success')

      router.push('/ticket-groups/' + payload.id + '/tickets')

      dispatch('fetchGroup', payload.id)
    } catch (error) {
      // 
    }

    $('.page-loader-wrapper').fadeOut()
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}