<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Tickets
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'tickets.create', params: { id: this.$route.params.id }}" tag="li">
              <a>
                <i class="material-icons">add</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="tickets-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Slug</th>
                <th>Price</th>
                <th>Display</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.slug }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.display }}</td>
                <td class="text-center">
                  <router-link :to="{ name: 'tickets.dashboard', params: { id: item.ticket_group_id, ticket_id: item.id }}">
                    <a>
                      <i class="material-icons">insert_chart</i>
                    </a>
                  </router-link>
                  <router-link :to="{ name: 'tickets.edit', params: { id: item.ticket_group_id, ticket_id: item.id }}">
                    <a>
                      <i class="material-icons">mode_edit</i>
                    </a>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        items: 'tickets/items',
      }),
    },
    methods: {
      ...mapActions({
        fetchTickets: 'tickets/fetchTickets',
      }),
    },
    mounted() {
      $('#tickets-table').DataTable({
        responsive: true,
      });
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchTickets(vm.$route.params.id)
      })
    }
  }
</script>