<template>
  <div id="create-event-page" class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>Create Ticket</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'event.tickets' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <div id="wizard_vertical">
            <section>
              <form @submit.prevent="formSubmit()">
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Group</h2>
                    <select class="form-control show-tick" data-live-search="true" required v-model="ticket.type">
                      <option value="">-- Please select --</option>
                      <option value="GROUP">GROUP</option>
                      <option value="TICKET">TICKET</option>
                    </select>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Title</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="text" class="form-control" placeholder="Title" required v-model="ticket.title">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Description</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <textarea rows="3" class="form-control no-resize" placeholder="Description" required v-model="ticket.description"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Quantity</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="number" class="form-control" placeholder="Quantity" required v-model="ticket.quantity">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-lg btn-primary waves-effect">SAVE</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #map {
    height: 500px;
    width: 100%;
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data() {
      return {
        ticket: {
          event_id: this.$route.params.id,
          title: '',
          description: '',
          quantity: '',
        }
      }
    },
    computed: {
      ...mapGetters({
        groups: 'events/groups',
      }),
    },
    methods: {
      ...mapActions({
        createTicketGroup: 'events/createTicketGroup',
      }),
      formSubmit: function() {
        this.createTicketGroup(this.ticket).then(() => {
          Object.assign(this.$data, this.$options.data.call(this))
        })
      },
    },
    mounted: function () {
      $.AdminBSB.input.activate();
      $.AdminBSB.select.activate();
    },
    updated: function() {

    },
    created: function() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {

      })
    }
  }
</script>