<template>
  <div class="">
    <div class="block-header">
      <h2>DASHBOARD</h2>
    </div>
    <div class="row clearfix">
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-pink hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">SALES</div>
            <div class="number count-to" data-from="0" v-bind:data-to="salesAll" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-cyan hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">SALES TODAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="salesToday" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-light-green hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">ORDERS</div>
            <div class="number count-to" data-from="0" v-bind:data-to="ordersAll" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">ORDERS TODAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="ordersToday" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-light-green hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PROMPTPAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="countPromptPay" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">OMISE</div>
            <div class="number count-to" data-from="0" v-bind:data-to="countOmise" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <!-- 
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">person_add</i>
          </div>
          <div class="content">
            <div class="text">NEW USERS TODAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="randomNumber()" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
       -->
    </div>
  </div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        orders: 'orders/orders',
        ordersAll: 'orders/ordersAll',
        ordersToday: 'orders/ordersToday',
        salesAll: 'orders/salesAll',
        salesToday: 'orders/salesToday',
        countPromptPay: 'orders/countPromptPay',
        countOmise: 'orders/countOmise',
      })
    },
    methods: {
      ...mapActions({
        fetchOrders: 'orders/fetchOrders',
      }),
      randomNumber: () => {
        return Math.floor(Math.random() * 1000) + 1;
      },
    },
    mounted() {
      $('.count-to').countTo();
    },
    created() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchOrders()
      })
    }
  }
</script>