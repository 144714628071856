<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            User
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'orders' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="order-table" class="table table-striped">
            <thead>
              <tr>
                <th width="10%">Name</th>
                <td v-if="!order.user.profile">-</td>
                <td v-if="order.user.profile">{{ order.user.profile.name }} {{ order.user.profile.surname }}</td>
              </tr>
              <tr>
                <th width="10%">Email</th>
                <td>{{ order.user.email }}</td>
              </tr>
              <tr>
                <th width="10%">Phone</th>
                <td v-if="!order.user.profile">-</td>
                <td v-if="order.user.profile">{{ order.user.profile.phone }}</td>
              </tr>
              <tr>
                <th width="10%">Citizen ID</th>
                <td v-if="!order.user.profile">-</td>
                <td v-if="order.user.profile">{{ order.user.profile.id_card }}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Order Slips
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'orders' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="order-table" class="table table-striped">
            <thead>
              <tr>
                <th width="10%">ID</th>
                <th>File</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="slip in order.slips" :key="slip.id">
                <th width="10%">{{ slip.id }}</th>
                <td>
                  <a :href="getSlipUrl(slip.image_url)" target="_blank">Link</a>
                </td>
                <td>
                  <a href="#" @click="getSlipPDF({ id: slip.id, reference: order.reference })">Download</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Confirm Payment
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'orders' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <form class="">
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-8 col-xs-7">
                <label for="">Payment Date</label>
                <div class="form-group">
                  <div class="form-line">
                    <input type="text" id="date" v-model="order.payment_date" class="form-control" required="">
                  </div>
                </div>
                <a href="#" @click="confirmPayment(order.id)">
                  <button type="button" class="btn btn-primary m-t-15 waves-effect">Confirm Payment</button>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Mail Status
          </h2>
        </div>
        <div class="body">
          <table id="mail-table" class="table table-striped">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Time</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in messages" :key="message.id">
                <td>{{ message.subject }}</td>
                <td>{{ message.timestamp }}</td>
                <td class="text-center">{{ message.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Order Items
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'orders' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="order-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Type</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.items" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.item.code }}</td>
                <td>{{ item.ticket.title }}</td>
                <td>{{ item.ticket.price }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 2.5em;">
      <a href="#" @click="updateOrder({ id: order.id, data: { status: 'CANCEL' } })">
        <button type="button" class="btn btn-danger m-t-15 waves-effect">Cancel Order</button>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        order: 'orders/order',
        messages: 'mails/messages',
      }),
    },
    methods: {
      ...mapActions({
        fetchOrder: 'orders/fetchOrder',
        updateOrder: 'orders/updateOrder',
        fetchMessages: 'mails/fetchMessages',
        getSlipPDF: 'orders/fetchSlipPDF',
      }),
      confirmPayment: function(orderId) {
        this.updateOrder({ id: orderId, data: { status: 'PAID', payment_date: $('#date').val() } })
      },
      getSlipUrl: (path) => {
        return process.env.VUE_APP_ROOT_API + path
      },
      initMailTable: () => {
        $('#mail-table').DataTable({
          dom: 'Bfrtip',
          responsive: false,
          buttons: []
        });
      },
    },
    mounted() {  
      this.fetchOrder(this.$route.params.id)
      this.fetchMessages(this.order.user.email)

      $('#date').bootstrapMaterialDatePicker({ format : 'YYYY-MM-DD HH:mm:ss' });
    },
    watch: {
      order: function() {
        this.fetchMessages(this.order.user.email)
      }
    },
    created() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {

      })
    },
  }
</script>