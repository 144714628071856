<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Tickets
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'events' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
            <router-link :to="{ name: 'event.tickets.create' }" tag="li">
              <a>
                <i class="material-icons">add</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="mainTable" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Title</th>
                <th>Slug</th>
                <th>Description</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in event.ticket_groups" :key="ticket.id">
                <td>{{ ticket.id }}</td>
                <td>
                  <span class="label" v-bind:class="ticketType(ticket)">{{ ticket.type }}</span>
                </td>
                <td>{{ ticket.title }}</td>
                <td>{{ ticket.slug }}</td>
                <td>{{ ticket.description }}</td>
                <td>{{ ticket.quantity }}</td>
                <td>
                  <router-link :to="{ name: 'event.tickets.edit', params: { ticket_groups_id: ticket.id }}">
                    <a>
                      <i class="material-icons">mode_edit</i>
                    </a>
                  </router-link>
                  <a href="#" @click="deleteTicketGroup({ event_id: $route.params.id, ticket_groups_id: ticket.id })"><i class="material-icons">delete</i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        event: 'events/event',
      }),
    },
    methods: {
      ...mapActions({
        fetchEvent: 'events/fetchEvent',
        deleteTicketGroup: 'events/deleteTicketGroup',
      }),
      ticketType: (ticket) => {
        return {
          'label-success': (ticket.type == 'GROUP'), 
          'label-primary': (ticket.type == 'TICKET'),
        }
      }
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchEvent(vm.$route.params.id)
      })
    }
  }
</script>