<template>
  <div id="app">
    <div class="page-loader-wrapper">
      <div class="loader">
        <div class="preloader">
          <div class="spinner-layer pl-red">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <p>Please wait...</p>
      </div>
    </div>

    <nav class="navbar">
      <div class="container-fluid">
        <div class="navbar-header">
          <a
            href="javascript:void(0);"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            aria-expanded="false"
          ></a>
          <a href="javascript:void(0);" class="bars"></a>
          <router-link to="/" class="navbar-brand"
            >CI E-Ticket Tool</router-link
          >
        </div>
      </div>
    </nav>

    <section>
      <aside id="leftsidebar" class="sidebar">
        <div class="user-info" v-if="login.token">
          <!-- <div class="image">
            <img src="/images/user.png" width="48" height="48" alt="User" />
          </div> -->
          <div class="info-container">
            <div
              class="name"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="user.profile"
            >
              {{ user.profile.firstname }} {{ user.profile.surname }}
            </div>
            <div class="email">{{ user.email }}</div>
            <div class="btn-group user-helper-dropdown">
              <i
                class="material-icons"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                >keyboard_arrow_down</i
              >
              <ul class="dropdown-menu pull-right">
                <li>
                  <a href="javascript:void(0);"
                    ><i class="material-icons">person</i>Profile</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" @click="logout()"
                    ><i class="material-icons">input</i>Sign Out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="menu">
          <ul class="list">
            <li class="header">MAIN NAVIGATION</li>
            <router-link
              :to="{ name: 'login' }"
              tag="li"
              exact
              v-if="login.status == ''"
            >
              <a>
                <i class="material-icons">lock_open</i>
                <span>Login</span>
              </a>
            </router-link>
            <router-link :to="{ name: 'home' }" tag="li" exact>
              <a>
                <i class="material-icons">home</i>
                <span>Home</span>
              </a>
            </router-link>
            <router-link :to="{ name: 'qr' }" tag="li" v-if="login.status">
              <a>
                <i class="material-icons">center_focus_strong</i>
                <span>QR</span>
              </a>
            </router-link>
            <router-link
              :to="{ name: 'users' }"
              tag="li"
              v-if="login.status && user.role == 'ADMIN'"
            >
              <a>
                <i class="material-icons">account_circle</i>
                <span>Users</span>
              </a>
            </router-link>
            <li :class="{ active: eventIsActive }" v-if="login.status">
              <a href="javascript:void(0);" class="menu-toggle">
                <i class="material-icons">list</i>
                <span>Events</span>
              </a>
              <ul class="ml-menu">
                <router-link :to="{ name: 'event.groups' }" tag="li">
                  <a class="waves-effect waves-block">
                    <i class="material-icons">layers</i>
                    <span>Groups</span>
                  </a>
                </router-link>
                <router-link :to="{ name: 'events' }" tag="li">
                  <a class="waves-effect waves-block">
                    <i class="material-icons">layers</i>
                    <span>Lists</span>
                  </a>
                </router-link>
              </ul>
            </li>
            <li
              :class="{ active: ticketIsActive }"
              v-if="login.status && user.role == 'ADMIN'"
            >
              <a href="javascript:void(0);" class="menu-toggle">
                <i class="material-icons">list</i>
                <span>Tickets</span>
              </a>
              <ul class="ml-menu">
                <router-link :to="{ name: 'ticket.groups' }" tag="li">
                  <a class="waves-effect waves-block">
                    <i class="material-icons">layers</i>
                    <span>Groups</span>
                  </a>
                </router-link>
              </ul>
            </li>
            <router-link
              :to="{ name: 'items' }"
              tag="li"
              v-if="login.status && user.role == 'ADMIN'"
            >
              <a>
                <i class="material-icons">local_movies</i>
                <span>Codes</span>
              </a>
            </router-link>
            <router-link
              :to="{ name: 'orders' }"
              tag="li"
              v-if="login.status && user.email != 'longbeach@cibkk.com'"
            >
              <a>
                <i class="material-icons">content_paste</i>
                <span>Orders</span>
              </a>
            </router-link>
            <li
              :class="{ active: reportIsActive }"
              v-if="login.status && user.email != 'longbeach@cibkk.com'"
            >
              <a href="javascript:void(0);" class="menu-toggle">
                <i class="material-icons">list</i>
                <span>Reports</span>
              </a>
              <ul class="ml-menu">
                <router-link :to="{ name: 'reports.orders' }" tag="li">
                  <a class="waves-effect waves-block">
                    <i class="material-icons">layers</i>
                    <span>Orders</span>
                  </a>
                </router-link>
                <router-link :to="{ name: 'reports.tickets' }" tag="li">
                  <a class="waves-effect waves-block">
                    <i class="material-icons">layers</i>
                    <span>Tickets</span>
                  </a>
                </router-link>
              </ul>
            </li>
            <li
              tag="li"
              v-if="
                login.status &&
                user.role == 'ADMIN' &&
                user.email != 'longbeach@cibkk.com'
              "
            >
              <a href="#" v-on:click="download('22009')">
                <i class="material-icons">download</i>
                <span>Export</span>
              </a>
            </li>
            <li
              tag="li"
              v-if="
                login.status &&
                user.role == 'ADMIN' &&
                user.email != 'longbeach@cibkk.com'
              "
            >
              <a href="#" v-on:click="download('20008')">
                <i class="material-icons">download</i>
                <span>Export (demo)</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="legal">
          <div class="copyright">
            &copy; 2018
            <a href="javascript:void(0);">CreateIntelligence Co., Ltd.</a>
          </div>
          <div class="version"><b>Version: </b> 1.0</div>
        </div>
      </aside>
      <aside id="rightsidebar" class="right-sidebar">
        <ul class="nav nav-tabs tab-nav-right" role="tablist">
          <li role="presentation" class="active">
            <a href="#skins" data-toggle="tab">SKINS</a>
          </li>
          <li role="presentation">
            <a href="#settings" data-toggle="tab">SETTINGS</a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            role="tabpanel"
            class="tab-pane fade in active in active"
            id="skins"
          >
            <ul class="demo-choose-skin">
              <li data-theme="red" class="active">
                <div class="red"></div>
                <span>Red</span>
              </li>
              <li data-theme="pink">
                <div class="pink"></div>
                <span>Pink</span>
              </li>
              <li data-theme="purple">
                <div class="purple"></div>
                <span>Purple</span>
              </li>
              <li data-theme="deep-purple">
                <div class="deep-purple"></div>
                <span>Deep Purple</span>
              </li>
              <li data-theme="indigo">
                <div class="indigo"></div>
                <span>Indigo</span>
              </li>
              <li data-theme="blue">
                <div class="blue"></div>
                <span>Blue</span>
              </li>
              <li data-theme="light-blue">
                <div class="light-blue"></div>
                <span>Light Blue</span>
              </li>
              <li data-theme="cyan">
                <div class="cyan"></div>
                <span>Cyan</span>
              </li>
              <li data-theme="teal">
                <div class="teal"></div>
                <span>Teal</span>
              </li>
              <li data-theme="green">
                <div class="green"></div>
                <span>Green</span>
              </li>
              <li data-theme="light-green">
                <div class="light-green"></div>
                <span>Light Green</span>
              </li>
              <li data-theme="lime">
                <div class="lime"></div>
                <span>Lime</span>
              </li>
              <li data-theme="yellow">
                <div class="yellow"></div>
                <span>Yellow</span>
              </li>
              <li data-theme="amber">
                <div class="amber"></div>
                <span>Amber</span>
              </li>
              <li data-theme="orange">
                <div class="orange"></div>
                <span>Orange</span>
              </li>
              <li data-theme="deep-orange">
                <div class="deep-orange"></div>
                <span>Deep Orange</span>
              </li>
              <li data-theme="brown">
                <div class="brown"></div>
                <span>Brown</span>
              </li>
              <li data-theme="grey">
                <div class="grey"></div>
                <span>Grey</span>
              </li>
              <li data-theme="blue-grey">
                <div class="blue-grey"></div>
                <span>Blue Grey</span>
              </li>
              <li data-theme="black">
                <div class="black"></div>
                <span>Black</span>
              </li>
            </ul>
          </div>
          <div role="tabpanel" class="tab-pane fade" id="settings">
            <div class="demo-settings">
              <p>GENERAL SETTINGS</p>
              <ul class="setting-list">
                <li>
                  <span>Report Panel Usage</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" checked /><span
                        class="lever"
                      ></span
                    ></label>
                  </div>
                </li>
                <li>
                  <span>Email Redirect</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" /><span class="lever"></span
                    ></label>
                  </div>
                </li>
              </ul>
              <p>SYSTEM SETTINGS</p>
              <ul class="setting-list">
                <li>
                  <span>Notifications</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" checked /><span
                        class="lever"
                      ></span
                    ></label>
                  </div>
                </li>
                <li>
                  <span>Auto Updates</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" checked /><span
                        class="lever"
                      ></span
                    ></label>
                  </div>
                </li>
              </ul>
              <p>ACCOUNT SETTINGS</p>
              <ul class="setting-list">
                <li>
                  <span>Offline</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" /><span class="lever"></span
                    ></label>
                  </div>
                </li>
                <li>
                  <span>Location Permission</span>
                  <div class="switch">
                    <label
                      ><input type="checkbox" checked /><span
                        class="lever"
                      ></span
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </section>

    <section class="content">
      <div class="container-fluid">
        <transition name="fade" mode="out-in">
          <!-- <keep-alive> -->
          <router-view />
          <!-- </keep-alive> -->
        </transition>
      </div>
    </section>
  </div>
</template>

<style type="text/css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script type="text/javascript">
import { mapState, mapGetters, mapActions } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      apiURL: process.env.VUE_APP_ROOT_API,
    }
  },
  computed: {
    ...mapState({
      login: 'login',
    }),
    ...mapGetters({
      token: 'token',
      user: 'user',
    }),
    eventIsActive() {
      return (
        this.$route.name === 'event.groups' || this.$route.name === 'events'
      )
    },
    ticketIsActive() {
      return (
        this.$route.name === 'ticket.groups' || this.$route.name === 'tickets'
      )
    },
    reportIsActive() {
      return this.$route.name === 'reports.orders'
    },
  },
  methods: {
    ...mapActions({
      fetchToken: 'fetchToken',
      fetchUser: 'fetchUser',
      authLogout: 'authLogout',
    }),
    logout: function () {
      this.authLogout().then(() => {
        this.$router.push('/')
      })
    },
    download: async function (prefixRef) {
      let params = {
        // order_by: 'id,desc',
        // includes: 'invoice',
        reference: prefixRef + '*',
      }

      Object.assign(params)

      let response = await axios({
        method: 'GET',
        url: '/v1/orders/export',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
        params: params,
        responseType: 'blob',
      })

      // console.log(response)

      var fileDownload = require('js-file-download')

      fileDownload(response.data, 'orders-export.xls')
    },
  },
  updated: function () {},
  created: function () {
    $('.page-loader-wrapper').fadeOut(100)
  },
  mounted: function () {
    if (localStorage.getItem('user-token')) {
      localStorage.setItem('token', localStorage.getItem('user-token'))
    } else if (!localStorage.getItem('user-token')) {
      this.fetchToken().then(() => {
        // this.$router.go(0)
      })
    }

    if (localStorage.getItem('user-token') && !localStorage.getItem('user')) {
      this.fetchUser()
    }
  },
}
</script>
