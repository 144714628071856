<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Images
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'events' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
            <router-link :to="{ name: 'event.images.create' }" tag="li">
              <a>
                <i class="material-icons">add</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="mainTable" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Position</th>
                <th class="text-center">Image</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="image in event.images" :key="image.id">
                <td>{{ image.id }}</td>
                <td>{{ image.position }}</td>
                <td class="text-center"><img :src="image.image_url" /></td>
                <td class="text-center">
                  <a href="#" @click="deleteImage({ event_id: $route.params.id, image_id: image.id })"><i class="material-icons">delete</i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        event: 'events/event',
      }),
    },
    methods: {
      ...mapActions({
        fetchEvent: 'events/fetchEvent',
        deleteImage: 'events/deleteImage',
      })
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchEvent(vm.$route.params.id)
      })
    }
  }
</script>