<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Orders
          </h2>
        </div>
        <div class="body">
          <form @submit.prevent="login">
            <table width="100%">
              <tr>
                <td>
                  <label for="">Gateway</label>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="promptpay" value="promptpay">
                      <span class="lever"></span>
                      Promptpay
                    </label>
                  </div>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="chillpay" value="chillpay">
                      <span class="lever"></span>
                      ChillPay
                    </label>
                  </div>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="omise" value="omise">
                      <span class="lever"></span>
                      Omise
                    </label>
                  </div>

                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="linepay" value="linepay">
                      <span class="lever"></span>
                      Line Pay
                    </label>
                  </div>
                </td>
                <td>
                  <label for="">Status</label>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="waiting" :value="waiting">
                      <span class="lever"></span>
                      Waiting
                    </label>
                  </div>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="paid" :value="paid">
                      <span class="lever"></span>
                      Paid
                    </label>
                  </div>
                </td>
                <td>
                  <label for="">Slips</label>
                  <div class="switch">
                    <label>
                      <input type="checkbox" v-model="has_slips" value="has_slips">
                      <span class="lever"></span>
                      Has Slip
                    </label>
                  </div>
                </td>
                <td>
                  <button type="button" class="btn btn-primary m-t-15 waves-effect" @click="filterItems">Filter</button>
                </td>
              </tr>
            </table>
          </form>
        </div>

        <div class="clearfix"></div>

        <!-- 
        <div id="order-section" class="body" style="display: none;">
          <table id="order-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Gateway</th>
                <th>Reference</th>
                <th class="text-center">Invoice</th>
                <th>Date</th>
                <th class="text-center">Status</th>
                <th class="text-center">Slips</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td><span class="label" v-bind:class="paymentType(order)">{{ order.payment_gateway || 'NONE' }}</span></td>
                <td>{{ order.reference }}</td>
                <td class="text-center" v-if="order.invoice">{{ order.invoice.reference }}</td>
                <td class="text-center" v-if="!order.invoice">-</td>
                <td>{{ order.created_at }}</td>
                <td class="text-center"><span class="label" v-bind:class="orderStatus(order)">{{ order.status }}</span></td>
                <td class="text-center" v-if="order.has_slips"><span class="label label-success">Yes</span></td>
                <td class="text-center" v-if="!order.has_slips"><span class="label label-danger">No</span></td>
                <td>
                  <router-link :to="{ name: 'orders.show', params: { id: order.id }}">
                    <a>
                      <i class="material-icons">search</i>
                    </a>
                  </router-link>
                  <span v-if="order.status != 'PAID' && order.status != 'CANCEL'">
                    <a href="#" @click="updateToPaid(order.id)"><i class="material-icons">done</i></a>
                    <a href="#" @click="updateOrder({ id: order.id, data: { status: 'CANCEL' } })"><i class="material-icons">cancel</i></a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
         -->

        <div class="body">
          <table id="example" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th class="text-center">Gateway</th>
                <th class="text-center">Reference</th>
                <th class="text-center">Invoice</th>
                <th class="text-center">Date</th>
                <th class="text-center">Status</th>
                <th class="text-center">Slips</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
  import store from '@/store/store'

  var table;
  var orderTable;

  export default {
    computed: {
      ...mapGetters({
        orders: 'orders/orders',
        promptpay: 'orders/promptpay',
        omise: 'orders/omise',
        has_slips: 'orders/has_slips',
      }),
      promptpay: {
        get: () => store.getters['orders/promptpay'],
        set: (promptpay) => store.commit('orders/promptpay', promptpay)
      },
      omise: {
        get: () => store.getters['orders/omise'],
        set: (omise) => store.commit('orders/omise', omise)
      },
      linepay: {
        get: () => store.getters['orders/linepay'],
        set: (linepay) => store.commit('orders/linepay', linepay)
      },
      waiting: {
        get: () => store.getters['orders/waiting'],
        set: (waiting) => store.commit('orders/waiting', waiting)
      },
      paid: {
        get: () => store.getters['orders/paid'],
        set: (paid) => store.commit('orders/paid', paid)
      },
      has_slips: {
        get: () => store.getters['orders/has_slips'],
        set: (has_slips) => store.commit('orders/has_slips', has_slips)
      },
    },
    methods: {
      ...mapActions({
        fetchOrders: 'orders/fetchOrders',
        updateOrder: 'orders/updateOrder',
        filterOrders: 'orders/filterOrders',
        // paid: 'orders/paid',
      }),
      paymentType: (order) => {
        return {
          'label-success': (order.payment_gateway == 'OMISE'), 
          'label-primary': (order.payment_gateway == 'PROMPTPAY'),
          'label-info': (order.payment_gateway == 'LINEPAY'),
          'label-warning': (order.payment_gateway == null),
        }
      },
      orderStatus: (order) => {
        return {
          'label-success': (order.status == 'PAID'), 
          'label-warning': (order.status == 'PENDING'),
          'label-info': (order.status == 'WAITING'),
          'label-danger': (order.status == 'CANCEL'),
        }
      },
      filterItems: function() {
        // $('.page-loader-wrapper').fadeIn(100)

        // table.destroy();

        let paymentGateway = "";
        let paymentStatus = "";

        // payment gateway
        if (this.promptpay) {
          paymentGateway = 'promptpay'
        } else if (this.omise) {
          paymentGateway = 'omise'
        } else if (this.linepay) {
          paymentGateway = 'linepay'
        } else if (this.chillpay) {
          paymentGateway = 'chillpay'
        }

        // payment status
        if (this.paid && this.waiting) {
          // paymentStatus = '[]=paid&status[]=waiting'
          paymentStatus = 'paid|waiting'
        } else if (this.paid) {
          paymentStatus = 'paid'
        } else if (this.waiting) {
          paymentStatus = 'waiting'
        }

        if (this.has_slips) {
          orderTable.destroy()

          this.initTable({
            search: {
              value: 'promptpay'
            },
            hasSlips: true,
          })
          
          orderTable
            .column(5)
            .search(paymentStatus, true)
            .draw();
        } else {
          orderTable.destroy()

          this.initTable()

          orderTable
            .column(1)
            .search(paymentGateway)
            .column(5)
            .search(paymentStatus, true)
            .draw();
        }

        // this.fetchOrders({ 
        //   payment_gateway: paymentGateway, 
        //   status: paymentStatus,
        //   has_slips: (this.has_slips) ? true : null,
        // })

        // $('#order-section').fadeIn('fast');

        // $('.page-loader-wrapper').fadeOut(100)
      },
      initTable: function(params) {
        orderTable = $('#example').DataTable( {
          "dom": 'Bfltip',
          "responsive": false,
          "buttons": [
            'excel',
          ],
          "lengthMenu": [
            [ 10, 25, 50, -1 ],
            [ '10', '25', '50', 'All' ]
          ],
          "processing": true,
          "serverSide": true,
          "ajax": {
            "url": process.env.VUE_APP_ROOT_API + "/v1/orders/_datatables",
            "data": function (d) {
              d = Object.assign(d, params)
            },
            beforeSend: function (xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('user-token'));
            },
          },
          "columns": [
            { "data": "id" },
            { 
              "className": "text-center", 
              "data": "payment_gateway",
              "render": function(data, type, row, meta) {
                let style = ''

                switch(data) {
                  case "PROMPTPAY":
                    style = 'label-primary'
                    break;
                  case "OMISE":
                    style = 'label-success'
                    break;
                  case "LINEPAY":
                    style = 'label-info'
                    break;
                  default:
                    style = 'label-warning'
                    break;
                }

                return `<span class="label ${style}">${data}</span>`
              }
            },
            {
              "className": "text-center", 
              "data": "reference" 
            },
            { 
              "searchable": false,
              "className": "text-center",
              "data": "invoice",
              "render": function(data, type, row, meta) {
                return (data) ? data.reference : '-'
              }
            },
            { 
              "className": "text-center",
              "data": "created_at" 
            },
            { 
              "className": "text-center",
              "data": "status",
              "render": function(data, type, row, meta) {
                let style = ''

                switch(data) {
                  case "CANCEL":
                    style = 'label-danger'
                    break;
                  case "PAID":
                    style = 'label-success'
                    break;
                  case "WAITING":
                    style = 'label-info'
                    break;
                  default:
                    style = 'label-warning'
                    break;
                }

                return `<span class="label ${style}">${data}</span>`
              }
            },
            { 
              "sortable": false,
              "searchable": false,
              "className": "text-center",
              "data": "has_slips",
              "name": "has_slips",
              "render": function(data, type, row, meta) {
                if (data) {
                  return `<span class="label label-success">Yes</span>`;
                } else {
                  return `<span class="label label-danger">No</span>`;
                }
              }
            },
            {
              "className": "text-center", 
              "data": "id",
              "render": function(data, type, row, meta) {
                return `
                    <a href="/orders/${data}">
                      <i class="material-icons">search</i>
                    </a>
                `
              }
            },
          ]
        }).on('processing.dt', function (e, settings, processing) {
          if (processing) {
            $('.page-loader-wrapper').fadeIn(100)
          } else {
            $('.page-loader-wrapper').fadeOut(100)
          }
        });
      }
    },
    mounted() {
      // setTimeout(function() {
      //   table = $('#order-table').DataTable({
      //     dom: 'Bfrtip',
      //     buttons: [],
      //   })
      // }, 500)

      this.initTable()
    },
    watch: {
      orders: function() {
        // setTimeout(function() {
        //   table = $('#order-table').DataTable({
        //     dom: 'Bfrtip',
        //     buttons: [],
        //     "initComplete": function(settings, json) {
        //       $('.page-loader-wrapper').fadeOut(100)
        //     }
        //   })
        // }, 500)
      },
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {

      })
    }
  }
</script>