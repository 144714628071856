<template>
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Sign In
          </h2>
          <ul class="header-dropdown m-r--5">
            <li class="dropdown">
              <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">more_vert</i>
              </a>
              <ul class="dropdown-menu pull-right">
                <li><a href="javascript:void(0);">Action</a></li>
                <li><a href="javascript:void(0);">Another action</a></li>
                <li><a href="javascript:void(0);">Something else here</a></li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="body">
          <form class="form-horizontal" @submit.prevent="login">
            <div class="row clearfix">
              <div class="col-lg-2 col-md-2 col-sm-4 col-xs-5 form-control-label">
                <label for="email">Email</label>
              </div>
              <div class="col-lg-10 col-md-10 col-sm-8 col-xs-7">
                <div class="form-group">
                  <div class="form-line">
                    <input type="text" v-model="email" id="email" class="form-control" placeholder="Enter your email address" required="">
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-2 col-md-2 col-sm-4 col-xs-5 form-control-label">
                <label for="password">Password</label>
              </div>
              <div class="col-lg-10 col-md-10 col-sm-8 col-xs-7">
                <div class="form-group">
                  <div class="form-line">
                    <input type="password" v-model="password" id="password" class="form-control" placeholder="Enter your password" required="">
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-lg-offset-2 col-md-offset-2 col-sm-offset-4 col-xs-offset-5">
                <button type="submit" class="btn btn-primary m-t-15 waves-effect">LOGIN</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        email: "",
        password: "",
      }
    },
    methods: {
      ...mapActions({
        authLogin: 'authLogin',
      }),
      login: function () {
        const { email, password } = this

        this.authLogin({ email, password }).then(() => {
          let redirectPath = localStorage.getItem('redirectPath') || '/'
          localStorage.removeItem('redirectPath')
          this.$router.push(redirectPath)
          this.$router.go(0)
        }).catch(error => {
          if (typeof error.response.data.message != 'undefined') {
            showMessage(error.response.data.status, error.response.data.message, 'error')
          } else {
            showMessage('error', error, 'error')
          }
        })
      },
    },
    components: {
      // 
    }
  }
</script>