<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            User
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'users' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="user-table" class="table table-striped">
            <tbody>
              <tr>
                <th width="10%">Name</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><p>{{ user.profile.name }}</p></td>
              </tr>
              <tr>
                <th width="10%">Surname</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><p>{{ user.profile.surname }}</p></td>
              </tr>
              <tr>
                <th width="10%">Email</th>
                <td><p>{{ user.email }}</p></td>
              </tr>
              <tr>
                <th width="10%">Phone</th>
                <td v-if="!user.profile">-</td>
                <td v-if="user.profile"><p>{{ user.profile.phone }}</p></td>
              </tr>
              <!-- <tr>
                <th width="10%">Citizen ID</th>
                <td>{{ user.profile.id_card }}</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <h2>
            Order
          </h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'users' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="user-order-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Gateway</th>
                <th>Reference</th>
                <th>Date</th>
                <th class="text-center">Status</th>
                <th class="text-center">Slips</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td><span class="label" v-bind:class="paymentType(order)">{{ order.payment_gateway || 'NONE' }}</span></td>
                <td>{{ order.reference }}</td>
                <td>{{ order.created_at }}</td>
                <td class="text-center"><span class="label" v-bind:class="orderStatus(order)">{{ order.status }}</span></td>
                <td class="text-center" v-if="order.has_slips"><span class="label label-success">Yes</span></td>
                <td class="text-center" v-if="!order.has_slips"><span class="label label-danger">No</span></td>
                <td>
                  <router-link :to="{ name: 'orders.show', params: { id: order.id }}">
                    <a>
                      <i class="material-icons">search</i>
                    </a>
                  </router-link>
                  <span v-if="order.status != 'PAID' && order.status != 'CANCEL'">
                    <!-- <a href="#" @click="updateToPaid(order.id)"><i class="material-icons">done</i></a>
                    <!-- <a href="#" @click="updateOrder({ id: order.id, data: { status: 'CANCEL' } })"><i class="material-icons">cancel</i></a> -->
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card">
        <div class="header">
          <h2>Mail</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'users' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <table id="mail-table" class="table table-striped">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Time</th>
                <th class="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in messages" :key="message.id">
                <td>{{ message.subject }}</td>
                <td>{{ message.timestamp }}</td>
                <td class="text-center">{{ message.type }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        user: 'users/user',
        orders: 'orders/orders',
        messages: 'mails/messages',
      }),
    },
    methods: {
      ...mapActions({
        fetchUser: 'users/fetchUser',
        fetchOrders: 'orders/fetchOrders',
        fetchMessages: 'mails/fetchMessages',
      }),
      paymentType: (order) => {
        return {
          'label-success': (order.payment_gateway == 'OMISE'), 
          'label-primary': (order.payment_gateway == 'PROMPTPAY'),
          'label-warning': (order.payment_gateway == null),
        }
      },
      orderStatus: (order) => {
        return {
          'label-success': (order.status == 'PAID'), 
          'label-warning': (order.status == 'PENDING'),
          'label-info': (order.status == 'WAITING'),
          'label-danger': (order.status == 'CANCEL'),
        }
      },
    },
    mounted() {
      
    },
    watch: {
      orders: function() {
        setTimeout(function() {
          $('#user-order-table').DataTable({
            dom: 'Bfrtip',
            buttons: [],
          })
        }, 500)
      }
    },
    created() {
      this.fetchMessages(this.user.email)
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchUser(vm.$route.params.id)
        vm.fetchOrders({
          user_id:vm.$route.params.id
        })
      })
    }
  }
</script>