import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth';
import events from '@/store/modules/events';
import tickets from '@/store/modules/tickets';
import items from '@/store/modules/items';
import orders from '@/store/modules/orders';
import login from '@/store/modules/login';
import user from '@/store/modules/user';
import mails from '@/store/modules/mails';
import users from '@/store/modules/users';

Vue.use(Vuex)

export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  strict: false,
  plugins: [createPersistedState()],
  modules: {
    auth,
    login,
    user,
    events,
    tickets,
    items,
    orders,
    mails,
    users,
  },
  state: {
    error: "",
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    error: (state, message) => {
      state.error = message
    },
  },
  actions: {
    addError: ({ commit }, message) => {
      commit('error', message)
    }
  }
})
