import axios from 'axios'
import router from '@/router/router'

const state = {
  item: [],
  items: [],
}

const getters = {
  user: state => state.item,
  users: state => state.items,
}

const mutations = {
  user: (state, payload) => state.item = payload,
  users: (state, payload) => state.items = payload,
}

const actions = {
  async fetchUsers({ commit }) {
    try {
      let params = {
        'order_by': 'id,asc',
        'limit': 30000,
      }

      let response = await axios({
        method: 'get',
        url: '/v1/users',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        params: params,
      })

      commit('users', response.data.data)
    } catch (error) {
      // 
    }
  },
  async fetchUser({ commit }, id) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/users/' + id,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('user', response.data.data)
    } catch (error) {
      // 
    }
  },
  async updateUser({ dispatch }, payload) {
    try {
      await axios({
        method: 'put',
        url: '/v1/users/' + payload.id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('User updated!', 'Your user updated!', 'success')

      router.push('/users/' + payload.id)
    } catch (error) {
      if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
        showMessage(error.response.data.status, error.response.data.message, 'error')
      } else if (error.response.data.data) {
        let html = '';

        $.each(error.response.data.data, function(key, val) {
          html += (key + ': ' + val + '</br>');
        })

        showMessage(error.response.data.status, html, 'error')
      }
    }
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}