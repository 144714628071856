import axios from 'axios'

const state = {
  item: [],
  items: [],
  promptpay: null,
  omise: null,
  chillpay: null,
  linepay: null,
  waiting: null,
  paid: null,
  has_slips: null,
}

const getters = {
  order: state => state.item,
  orders: state => state.items,
  promptpay: state => state.promptpay,
  omise: state => state.omise,
  chillpay: state => state.chillpay,
  linepay: state => state.linepay,
  waiting: state => state.waiting,
  paid: state => state.paid,
  has_slips: state => state.has_slips,
  salesAll: state => {
    let obj = state.items.filter(item => {
      return item.status == "PAID"
    })

    let total = 0;

    for (var i = 0; i < obj.length; i++) {
      total += obj[i].total
    }

    return total
  },
  salesToday: state => {
    let obj = state.items.filter(item => {
      let date1 = new Date(item.created_at);
      let date2 = new Date();

      return item.status == "PAID" && (date1.setHours(0,0,0,0) == date2.setHours(0,0,0,0));
    })

    let total = 0;

    for (var i = 0; i < obj.length; i++) {
      total += obj[i].total
    }

    return total
  },
  ordersAll: state => {
    return state.items.length
  },
  ordersToday: state => {
    return state.items.filter(item => {
      let date1 = new Date(item.created_at);
      let date2 = new Date();

      return date1.setHours(0,0,0,0) == date2.setHours(0,0,0,0)
    }).length
  },
  countPromptPay: state => {
    return state.items.filter(item => {
      return item.payment_gateway == "PROMPTPAY"
    }).length
  },
  countOmise: state => {
    return state.items.filter(item => {
      return item.payment_gateway == "OMISE"
    }).length
  },
  reservedQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.status == "PAID" || item.status == "WAITING"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  promptpayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "PROMPTPAY"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  omiseQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "OMISE"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  chillpayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "CHILLPAY"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  linepayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "LINEPAY"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  paidPromptpayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "PROMPTPAY" && item.status == "PAID"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  paidOmiseQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "OMISE" && item.status == "PAID"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  paidChillpayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "CHILLPAY" && item.status == "PAID"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  paidLinepayQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == "LINEPAY" && item.status == "PAID"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  paidQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.status == "PAID"
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  noneQuantity: state => {
    return ticket_id => {
      let obj = state.items.filter(item => {
        return item.payment_gateway == null
      })

      let total = 0;

      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < obj[i].tickets.length; j++) {
          if (obj[i].tickets[j].ticket_id == ticket_id) {
            total += obj[i].tickets[j].quantity
          }
        }
      }

      return total 
    }
  },
  ordersByTicket: state => {
    return ticketId => {
      return state.items.filter(item => {
        for (var j = 0; j < item.tickets.length; j++) {
          if (item.tickets[j].ticket_id == ticketId) {
            return item
          }
        }
      })
    }
  }
}

const mutations = {
  order: (state, payload) => state.item = payload,
  orders: (state, payload) => state.items = payload,
  promptpay: (state, payload) => state.promptpay = payload,
  omise: (state, payload) => state.omise = payload,
  chillpay: (state, payload) => state.chillpay = payload,
  linepay: (state, payload) => state.linepay = payload,
  waiting: (state, payload) => state.waiting = payload,
  paid: (state, payload) => state.paid = payload,
  has_slips: (state, payload) => state.has_slips = payload,
}

const actions = {
  async fetchOrders({ commit }, payload = {}) {
    try {
      let filter_has_slips = false
      let params = {
        'order_by': 'id,desc',
        'limit': 100,
        'includes': 'invoice',
      }
      
      Object.assign(params, payload)

      // filter has slips
      if (params.has_slips) {
        filter_has_slips = true
        delete params.has_slips
      }

      let response = await axios({
        method: 'get',
        url: '/v1/orders', 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        params: params,
      })

      let json = response.data.data

      // filter has slips
      if (filter_has_slips) {
        json = json.filter(item => {
          return item.has_slips == true
        })
      }

      commit('orders', json)
    } catch (err) {
      //
    }
  },
  async fetchOrder({ commit }, id) {
    try {
      let response = await axios({
        method: 'get',
        url: '/v1/orders/' + id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
      })

      commit('order', response.data.data)
    } catch (err) {
      // console.log(err) 
    }
  },
  async updateOrder({ dispatch }, payload) {
    try {
      await axios({
        method: 'put',
        url: '/v1/orders/' + payload.id, 
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
        },
        data: payload.data,
      })

      showMessage('Order updated!', 'Your order updated!', 'success')

      dispatch('fetchOrders', payload.id)
    } catch (error) {
      if (typeof error.response.data.message != 'undefined' && error.response.data.message) {
        showMessage(error.response.data.status, error.response.data.message, 'error')
      } else if (error.response.data.data) {
        let html = '';

        $.each(error.response.data.data, function(key, val) {
          html += (key + ': ' + val + '</br>');
        })

        showMessage(error.response.data.status, html, 'error')
      }
    }
  },
  async fetchSlipPDF({ commit }, payload) {
    axios({
      method: 'get',
      url: '/v1/slips/' + payload.id + '/pdf', 
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('user-token'),
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'slip-' + payload.reference + '.pdf');

      document.body.appendChild(link);

      link.click();
    })
  },
}

export default {
  namespaced: true,
	state,
	getters,
	mutations,
	actions,
}