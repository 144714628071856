<template>
  <div id="create-event-page" class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>Edit Image</h2>
          <ul class="header-dropdown m-r--5">
            <router-link :to="{ name: 'event.images' }" tag="li" exact>
              <a>
                <i class="material-icons">keyboard_backspace</i>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="body">
          <div id="wizard_vertical">
            <section>
              <form @submit.prevent="formSubmit()">
                <div class="row clearfix">
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">Position</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="number" class="form-control" placeholder="Position" required v-model="image.position">
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <h2 class="card-inside-title">URL</h2>
                    <div class="form-group form-float">
                      <div class="form-line">
                        <input type="url" class="form-control" placeholder="URL" required v-model="image.image_url">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-block btn-lg btn-primary waves-effect">SAVE</button>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  #map {
    height: 500px;
    width: 100%;
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { mapFields } from 'vuex-map-fields'

  export default {
    computed: {
      ...mapFields({
        image: 'events.image',
      })
    },
    methods: {
      ...mapActions({
        fetchImage: 'events/fetchImage',
        updateImage: 'events/updateImage',
      }),
      formSubmit: function() {
        this.updateImage({
          event_id: this.$route.params.event_id,
          image_id: this.$route.params.image_id,
          data: this.image
        })
      },
    },
    mounted: function () {
      $.AdminBSB.input.activate();
      $.AdminBSB.select.activate();
    },
    updated: function() {

    },
    created: function() {

    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchImage({
          event_id: vm.$route.params.id, 
          image_id: vm.$route.params.image_id
        });
      })
    }
  }
</script>