<template>
  <div class="qr">
  	<div class="">
    	<!-- <h1>This is an about page</h1> -->
    	<video id="preview" playsinline="true"></video>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  // const Instascan = require('instascan');
  // const jsQR = require("jsqr");
  const axios = require('axios').default;
  const MobileDetect = require('mobile-detect');

  export default {
    computed: {},
    methods: {},
    mounted() {
      let self = this
      let md = new MobileDetect(window.navigator.userAgent);

      let scanner = new Instascan.Scanner({ 
      	video: document.getElementById('preview'),
      	mirror: false,
        refractoryPeriod: 5000,
        continuous: true,
        captureImage: true,
        backgroundScan: false,
        scanPeriod: 5
      });

      function justDownload(image) {
          var d = new Date();
          var n = d.toLocaleString();
          var link = document.createElement('a');
          link.setAttribute('href', image.replace('webp','jpg'));
          link.setAttribute('download', n + '.jpg');
          link.setAttribute('target', '_blank');
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }

      scanner.addListener('scan', function (code, image) {
        // console.log(code);
        // console.log(image);

        $('.page-loader-wrapper').fadeIn(100)

        // hard code
        // if (code == 'https://qrco.de/bbHWG7') {
        //   code = 'LBISFREE'
        // }

        axios({
          method: 'get',
          url: '/v1/verify/' + code, 
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        }).then(function (response) {
          // console.log(response.data)

          $('.page-loader-wrapper').fadeOut(100)

          // self.$router.push('/qr/success')
          
          window.location.href = '/qr/success'

          // swal("Verify succeed!", "", "success")
        }).catch(function (error) {
          // console.log(error.response.data);

          // handle with offline image
          // justDownload(image)

          $('.page-loader-wrapper').fadeOut(100)

          swal(error.response.data.message, "", "error")
        })
      });
      
      Instascan.Camera.getCameras().then(function (cameras) {
        scanner.stop()
      	if (cameras.length > 1) {
          if (md.is('iPhone')) {
            scanner.start(cameras[0]);
          } else {
            scanner.start(cameras[1]);  
          }
        } else if (cameras.length > 0) {
        	scanner.start(cameras[0]);
        } else {
          console.error('No cameras found.');
        }
      }).catch(function (e) {
        console.error(e);
      });
    },
    created() {

    }
  }
</script>