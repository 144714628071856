<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Reports: Orders
          </h2>
        </div>
        <div id="order-section" class="body" style="display: none;">
          <table id="order-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Reference</th>
                <th class="text-center">Invoice</th>
                <th>Email</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Amount</th>
                <th>Gateway</th>
                <th>Date</th>
                <th>Status</th>
                <th>Payment Date</th>
                <th>Total(THB)</th>
                <th>Fee</th>
                <th class="hide">OmiseID</th>
                <th class="hide">Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders">
                <td>{{ order.id }}</td>
                <td>{{ order.reference }}</td>
                <td class="text-center" v-if="order.invoice">{{ order.invoice.reference }}</td>
                <td class="text-center" v-if="!order.invoice">-</td>
                <td>{{ order.user.email }}</td>
                <td v-if="!order.user.profile">-</td>
                <td v-if="!order.user.profile">-</td>
                <td v-if="order.user.profile">{{ order.user.profile.name + ' ' + order.user.profile.surname }}</td>
                <td v-if="order.user.profile">{{ order.user.profile.phone }}</td>
                <td>{{ order.amount }}</td>
                <td><span class="label" v-bind:class="paymentType(order)">{{ order.payment_gateway || 'NONE' }}</span></td>
                <td>{{ order.created_at }}</td>
                <td><span class="label" v-bind:class="orderStatus(order)">{{ order.status }}</span></td>
                <td>{{ order.payment_date || '-' }}</td>
                <td>{{ order.total }}</td>
                <td v-if="order.payment_gateway == 'OMISE'">{{ (order.omise_fee + order.omise_vat) / 100 }}</td>
                <td v-if="order.payment_gateway == 'PROMPTPAY'">0</td>
                <td v-if="order.payment_gateway == 'LINEPAY'">{{ (order.linepay_fee + order.linepay_vat) }}</td>
                <td v-if="order.payment_gateway == null">0</td>
                <td class="hide">{{ order.charge_id }}</td>
                <td width="10%" class="hide">
                  <p v-for="slip in order.slips" :key="slip.id">
                    <a :href="getSlipUrl(slip.image_url)" target="_blank">{{ getSlipUrl(slip.image_url) }}</a> |
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  var table;

  export default {
    computed: {
      ...mapGetters({
        orders: 'orders/orders',
      }),
    },
    methods: {
      ...mapActions({
        fetchOrders: 'orders/fetchOrders',
        updateOrder: 'orders/updateOrder',
      }),
      paymentType: (order) => {
        return {
          'label-success': (order.payment_gateway == 'OMISE'), 
          'label-primary': (order.payment_gateway == 'PROMPTPAY'),
          'label-info': (order.payment_gateway == 'LINEPAY'),
          'label-warning': (order.payment_gateway == null),
        }
      },
      orderStatus: (order) => {
        return {
          'label-success': (order.status == 'PAID'), 
          'label-warning': (order.status == 'PENDING'),
          'label-info': (order.status == 'WAITING'),
          'label-danger': (order.status == 'CANCEL'),
        }
      },
      getSlipUrl: (path) => {
        return process.env.VUE_APP_ROOT_API + path
      },
    },
    watch: {
      orders: function() {
        setTimeout(function() {
          table = $('#order-table').DataTable({
            dom: 'Bfrtip',
            responsive: false,
            buttons: ['excel'],
            "initComplete": function(settings, json) {
              $('#order-section').fadeIn(100)
            }
          })
        }, 500)
      },
    },
    created() {
      this.fetchOrders()
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {

      })
    }
  }
</script>