<template>
  <div class="">
    <div class="block-header">
      <h2>{{ ticket.title }}</h2>
    </div>
    <div class="row clearfix">
      <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-pink hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">RESERVED</div>
            <div class="number count-to" data-from="0" v-bind:data-to="reservedQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div> -->
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-light-green hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PROMPTPAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="promptpayQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">OMISE</div>
            <div class="number count-to" data-from="0" v-bind:data-to="omiseQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-pink hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">LINEPAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="linepayQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">NONE</div>
            <div class="number count-to" data-from="0" v-bind:data-to="noneQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-light-green hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PAID - PROMPTPAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="paidPromptpayQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PAID : OMISE</div>
            <div class="number count-to" data-from="0" v-bind:data-to="paidOmiseQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-pink hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PAID - LINEPAY</div>
            <div class="number count-to" data-from="0" v-bind:data-to="paidLinepayQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <div class="info-box bg-orange hover-expand-effect">
          <div class="icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <div class="content">
            <div class="text">PAID - ALL</div>
            <div class="number count-to" data-from="0" v-bind:data-to="paidQuantity(ticket.id)" data-speed="1000" data-fresh-interval="20"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-header">
      <h2>Orders</h2>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div id="order-section" class="body" style="display: none;">
            <table id="order-table" class="table table-striped" width="100%">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Reference</th>
                  <th class="text-center">Invoice</th>
                  <th class="hide">Email</th>
                  <th>Name</th>
                  <th class="hide">Mobile</th>
                  <th class="text-center">Amount</th>
                  <th>Gateway</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Payment Date</th>
                  <th class="text-right">Total(THB)</th>
                  <th class="text-right" >Fee</th>
                  <!-- <th class="hide">OmiseID</th> -->
                  <!-- <th class="hide">Link</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in ordersByTicket(ticket.id)">
                  <td>{{ order.id }}</td>
                  <td>{{ order.reference }}</td>
                  <td class="text-center" v-if="order.invoice">{{ order.invoice.reference }}</td>
                  <td class="text-center" v-if="!order.invoice">-</td>
                  <td class="hide">{{ order.user.email }}</td>
                  <td v-if="!order.user.profile">-</td>
                  <td class="hide" v-if="!order.user.profile">-</td>
                  <td v-if="order.user.profile">{{ order.user.profile.name + ' ' + order.user.profile.surname }}</td>
                  <td class="hide" v-if="order.user.profile">{{ order.user.profile.phone }}</td>
                  <template v-for="orderTicket in order.tickets">
                    <td v-if="orderTicket.ticket_id == ticket.id" class="text-center">{{ orderTicket.quantity }}</td>
                  </template>
                  <td><span class="label" v-bind:class="paymentType(order)">{{ order.payment_gateway || 'NONE' }}</span></td>
                  <td>{{ order.created_at }}</td>
                  <td><span class="label" v-bind:class="orderStatus(order)">{{ order.status }}</span></td>
                  <td>{{ order.payment_date || '-' }}</td>
                  <td class="text-right">{{ order.total }}</td>
                  <td class="text-right" v-if="order.payment_gateway == 'OMISE'">{{ (order.omise_fee + order.omise_vat) / 100 }}</td>
                  <td class="text-right" v-if="order.payment_gateway == 'PROMPTPAY'">0.00</td>
                  <td class="text-right" v-if="order.payment_gateway == 'LINEPAY'">{{ (order.linepay_fee + order.linepay_vat) }}</td>
                  <td class="text-right" v-if="order.payment_gateway == null">0.00</td>
                  <!-- <td class="hide">{{ order.charge_id }}</td>
                  <td width="10%" class="hide">
                    <p v-for="slip in order.slips" :key="slip.id">
                      <a :href="getSlipUrl(slip.image_url)" target="_blank">{{ getSlipUrl(slip.image_url) }}</a> |
                    </p>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
  import store from '@/store/store'

  var table;

  export default {
    computed: {
      ...mapGetters({
        ticket: 'tickets/item',
        orders: 'orders/orders',
        // reservedQuantity: 'orders/reservedQuantity',
        promptpayQuantity: 'orders/promptpayQuantity',
        omiseQuantity: 'orders/omiseQuantity',
        linepayQuantity: 'orders/linepayQuantity',
        noneQuantity: 'orders/noneQuantity',
        paidPromptpayQuantity: 'orders/paidPromptpayQuantity',
        paidOmiseQuantity: 'orders/paidOmiseQuantity',
        paidLinepayQuantity: 'orders/paidLinepayQuantity',
        paidQuantity: 'orders/paidQuantity',
        ordersByTicket: 'orders/ordersByTicket',
      }),
    },
    methods: {
      ...mapActions({
        fetchOrders: 'orders/fetchOrders',
        fetchTicket: 'tickets/fetchTicket',
      }),
      paymentType: (order) => {
        return {
          'label-success': (order.payment_gateway == 'OMISE'), 
          'label-primary': (order.payment_gateway == 'PROMPTPAY'),
          'label-info': (order.payment_gateway == 'LINEPAY'),
          'label-warning': (order.payment_gateway == null),
        }
      },
      orderStatus: (order) => {
        return {
          'label-success': (order.status == 'PAID'), 
          'label-warning': (order.status == 'PENDING'),
          'label-info': (order.status == 'WAITING'),
          'label-danger': (order.status == 'CANCEL'),
        }
      },
      getSlipUrl: (path) => {
        return process.env.VUE_APP_ROOT_API + path
      },
    },
    mounted() {
      $('.count-to').countTo();
    },
    created() {
      this.fetchOrders()
      this.ordersByTicket(this.ticket.id)
    },
    watch: {
      orders: function() {
        setTimeout(function() {
          table = $('#order-table').DataTable({
            dom: 'Bfrtip',
            responsive: false,
            buttons: [],
            "initComplete": function(settings, json) {
              $('#order-section').fadeIn(100)
            }
          })
        }, 500)
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchTicket({
          group_id: vm.$route.params.id,
          ticket_id: vm.$route.params.ticket_id
        })
      })
    }
  }
</script>