import Vue from 'vue'
import store from '@/store/store'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import QR from '@/views/QR.vue'
import QRSuccess from '@/views/QRSuccess.vue'
import QRFail from '@/views/QRFail.vue'
import Users from '@/views/Users.vue'
import UsersEdit from '@/views/Users/Edit.vue'
import UsersOrders from '@/views/Users/Orders.vue'
import EventGroups from '@/views/EventGroups/Index.vue'
import EventGroupsCreate from '@/views/EventGroups/Create.vue'
import EventGroupsEdit from '@/views/EventGroups/Edit.vue'
import Events from '@/views/Events.vue'
import EventsCreate from '@/views/Events/Create.vue'
import EventsEdit from '@/views/Events/Edit.vue'
import EventImages from '@/views/Events/Images/Index.vue'
import EventImagesCreate from '@/views/Events/Images/Create.vue'
import EventImagesEdit from '@/views/Events/Images/Edit.vue'
import EventTickets from '@/views/Events/Tickets/Index.vue'
import EventTicketsCreate from '@/views/Events/Tickets/Create.vue'
import EventTicketsEdit from '@/views/Events/Tickets/Edit.vue'
import EventOrders from '@/views/Events/Orders/Index.vue'
import TicketGroups from '@/views/TicketGroups/Index.vue'
import TicketGroupsCreate from '@/views/TicketGroups/Create.vue'
import TicketGroupsEdit from '@/views/TicketGroups/Edit.vue'
import Tickets from '@/views/Tickets/Index.vue'
import TicketsDashboard from '@/views/Tickets/Dashboard.vue'
import TicketsCreate from '@/views/Tickets/Create.vue'
import TicketsEdit from '@/views/Tickets/Edit.vue'
import Items from '@/views/Items.vue'
import Orders from '@/views/Orders.vue'
import OrdersShow from '@/views/Orders/Show.vue'
import Login from '@/views/Login.vue'
import ReportsOrders from '@/views/Reports/Orders.vue'
import ReportsTickets from '@/views/Reports/Tickets.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }

  next('/')
}

const ifAuthenticated = (to, from, next) => {
  localStorage.setItem('redirectPath', to.path)
  
  if (store.getters.isAuthenticated) {
    next()
    return
  }

  next('/login')
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/qr',
    name: 'qr',
    component: QR,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/qr/success',
    name: 'qr.success',
    component: QRSuccess,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/qr/fail',
    name: 'qr.fail',
    component: QRFail,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/:id',
    name: 'users.edit',
    component: UsersEdit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users/:id/orders',
    name: 'users.orders',
    component: UsersOrders,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/event-groups',
    name: 'event.groups',
    component: EventGroups,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/event-groups/create',
    name: 'event.groups.create',
    component: EventGroupsCreate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/event-groups/:id/edit',
    name: 'event.groups.edit',
    component: EventGroupsEdit,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/events',
    name: 'events',
    component: Events,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/create',
    name: 'events.create',
    component: EventsCreate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/edit',
    name: 'events.edit',
    component: EventsEdit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/images',
    name: 'event.images',
    component: EventImages,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/images/create',
    name: 'event.images.create',
    component: EventImagesCreate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/tickets',
    name: 'event.tickets',
    component: EventTickets,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/tickets/create',
    name: 'event.tickets.create',
    component: EventTicketsCreate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/tickets/:ticket_groups_id/edit',
    name: 'event.tickets.edit',
    component: EventTicketsEdit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/events/:id/orders',
    name: 'event.orders',
    component: EventOrders,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ticket-groups',
    name: 'ticket.groups',
    component: TicketGroups,
    beforeEnter: ifAuthenticated,
  },
  {    
    path: '/ticket-groups/create', 
    name: 'ticket.groups.create',
    component: TicketGroupsCreate,
  },
  {    
    path: '/ticket-groups/:id/edit', 
    name: 'ticket.groups.edit',
    component: TicketGroupsEdit,
  },
  {
    path: '/ticket-groups/:id/tickets',
    name: 'tickets',
    component: Tickets,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ticket-groups/:id/tickets/:ticket_id/dashboard',
    name: 'tickets.dashboard',
    component: TicketsDashboard,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ticket-groups/:id/tickets/create',
    name: 'tickets.create',
    component: TicketsCreate,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/ticket-groups/:id/tickets/:ticket_id/edit',
    name: 'tickets.edit',
    component: TicketsEdit,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/items',
    name: 'items',
    component: Items,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/orders/:id',
    name: 'orders.show',
    component: OrdersShow,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reports/orders',
    name: 'reports.orders',
    component: ReportsOrders,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/reports/tickets',
    name: 'reports.tickets',
    component: ReportsTickets,
    beforeEnter: ifAuthenticated,
  },
]

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes,
})
