<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="card">
        <div class="header">
          <h2>
            Reports: Tickets
          </h2>
        </div>
        <div class="body">
          <table id="tickets-table" class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Ticket</th>
                <th class="text-center">Quantity</th>
                <th class="text-center">OrderID</th>
                <th class="text-center">InvoiceID</th>
                <th class="text-center">Code</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="order in orders">
                <tr v-if="order.invoice" v-for="item in order.items" :key="item.id">
                  <td>{{ order.id }}</td>
                  <td v-if="!order.user.profile">-</td>
                  <td v-if="order.user.profile">{{ order.user.profile.name }} {{ order.user.profile.surname }}</td>
                  <td>{{ item.ticket.title }}</td>
                  <td class="text-center">1</td>
                  <td class="text-center">{{ order.reference }}</td>
                  <td class="text-center" v-if="order.invoice">{{ order.invoice.reference }}</td>
                  <td class="text-center" v-if="!order.invoice">-</td>
                  <td class="text-center">{{ item.item.code }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        orders: 'orders/orders',
      }),
    },
    methods: {
      ...mapActions({
        fetchOrders: 'orders/fetchOrders',
      }),
    },
    mounted() {
      $('#tickets-table').DataTable({
        dom: 'Bfrtip',
        responsive: false,
        buttons: ['excel']
      });
    },
    created() {
      
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.fetchOrders()
      })
    }
  }
</script>